import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Container, Row, Col } from "react-bootstrap";
import ShareAppButtons from "../../components/ShareAppButtons";
import "./JobseekersMenu.css";

export default function JobseekersMenu(props, context) {
  const language = useSelector((state) => state.i18nState.lang);
  const profile = useSelector((state) => state.ProfileState.data);
  const notifications = useSelector((state) => state.NotificationsState.items);

  const hotlineNumbers = {
    kh: [{ number: "1800010181", label: "Free hotline" }],
    "mm-z": [
      { number: "1800010180", label: "Thailand Hotline (free)" },
      { number: "09765000150", label: "Yangon Hotline" },
    ],
    "mm-u": [
      { number: "1800010180", label: "Thailand Hotline (free)" },
      { number: "09765000150", label: "Yangon Hotline" },
    ],
    ne: [{ number: "+977 9765415706", label: "Nepal Hotline (free)" }],
  };

  const getHotlineNumber = (language) => {
    return hotlineNumbers[language]?.map((hotline, index) => (
      <Col xs={4} className="mt-3" key={index}>
        <a href={`tel: ${hotline.number}`} className="NavigationBar-icon">
          <i className="fa-solid fa-phone-square fa-2x"></i>
          <span> {context.t(hotline.label)} </span>
        </a>
      </Col>
    ));
  };

  const unreadNotificationsCount = (notificationType) => {
    const count = notificationType.reduce(
      (acc, type) => acc + (notifications[type] || 0),
      0
    );
    return count > 9 ? "+9" : count === 0 ? "" : count;
  };

  const totalNotifications = Object.values(notifications).reduce(
    (total, count) => total + count,
    0
  );

  return (
    <Container style={{ wordBreak: "break-word" }}>
      <Row className="mt-4">
        <Col xs={4}>
          <Link to="/recruitment-agencies" className="NavigationBar-icon">
            <i className="fa-solid fa-id-card fa-2x"></i>
            <span> {context.t("Recruiters")} </span>
          </Link>
        </Col>
        <Col xs={4}>
          <Link to="/employers" className="NavigationBar-icon">
            <i className="fa-solid fa-briefcase fa-2x"></i>
            <span> {context.t("Employers")} </span>
          </Link>
        </Col>
        <Col xs={4}>
          <Link to="/services" className="NavigationBar-icon">
            <i className="fa-solid fa-life-ring fa-2x"></i>
            <span> {context.t("Services")} </span>
          </Link>
        </Col>
        <Col xs={4} className="mt-3">
          <Link to="/problems" className="NavigationBar-icon">
            <i className="fa-solid fa-bullhorn fa-2x"></i>
            <span> {context.t("Share a problem")} </span>
          </Link>
        </Col>

        {getHotlineNumber(language)}

        <Col xs={4} className="mt-3">
          <Link to="/change-language" className="NavigationBar-icon">
            <i className="fa-solid fa-globe-asia fa-2x"></i>
            <span> {context.t("Language")} </span>
          </Link>
        </Col>
        {language !== "bn" && profile && profile.type !== "IS" && (
          <Col xs={4} className="mt-3">
            <Badge
              pill
              variant="danger"
              size="sm"
              className="navigationItemCountBadge"
            >
              {unreadNotificationsCount([
                "APPLICATION_ACCEPTED",
                "APPLICATION_DOWNLOADED",
                "APPLICATION_REJECTED",
                "APPLICATION_SHORTLISTED",
                "APPLICATION_VIEWED",
                "NEW_JOB_POSTED",
              ])}
            </Badge>
            <Link to={"/marketplace"} className="NavigationBar-icon">
              <img
                src="/static/media/icon-marketplace.png"
                style={{ height: 30.6 }}
              />
              <span> {context.t("Marketplace")} </span>
            </Link>
          </Col>
        )}
        <Col xs={4} className="mt-3">
          {totalNotifications > 0 && (
            <Badge pill variant="danger" className="navigationItemCountBadge">
              {totalNotifications > 9 ? "9+" : totalNotifications}
            </Badge>
          )}
          <Link to="/notifications" className="NavigationBar-icon">
            <i className="fa-solid fa-bell fa-2x"></i>
            <span> {context.t("Notifications")}</span>
          </Link>
        </Col>
        <Col xs={4} className="mt-3">
          <ShareAppButtons title={context.t("GoldenDreams App")} />
          <span> {context.t("Share App")}</span>
        </Col>
        <Col xs={4} className="mt-3">
          <Link to="/groups" className="NavigationBar-icon">
            <i className="fa-solid fa-comments fa-2x"></i>
            <span> {context.t("Groups")}</span>
          </Link>
        </Col>
        {profile && profile.type === "IS" && (
          <Col xs={4} className="mt-3">
            <Link
              to="/surveys"
              className="NavigationBar-icon"
              style={{ color: "orange" }}
            >
              <i className="fa-solid fa-pen fa-2x"></i>
              <span> {context.t("Surveys")}</span>
            </Link>
          </Col>
        )}
      </Row>
    </Container>
  );
}

JobseekersMenu.contextTypes = {
  t: PropTypes.func.isRequired,
};
