import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Image } from "react-bootstrap";
import { updateProfile } from "../common/redux/actions/ProfileActions";

export default function ChooseLanguageView(props) {
  const dispatch = useDispatch();

  const languagesArray = useSelector(
    (state) => state.LanguagesState.itemsArray
  );
  const profile = useSelector((state) => state.ProfileState.data);

  const chooseLanguage = (event) => {
    const { name, languageid } = event.target.attributes;
    const preferredLanguage = name.value.toLowerCase();
    const preferredLanguageId = languageid.value;
    let recentPage = localStorage.getItem("recentPage") || "/";

    if (recentPage === "/change-language") {
      recentPage = "/";
    }

    event.target.classList.add("pressed");

    if (profile.id) {
      dispatch(
        updateProfile(profile.type, profile.id, {
          preferred_language: preferredLanguageId,
        })
      ).finally(() => {
        window.location.href = recentPage;
      });
    } else {
      localStorage.setItem("language", preferredLanguage);
      window.location.href = recentPage;
    }
  };

  const filteredLanguages = languagesArray
    .filter((language) => !["hi", "th", "ms"].includes(language.code))
    .sort((a, b) => (a.code < b.code ? -1 : 1));

  return (
    <Row style={{ margin: "auto", wordBreak: "break-word" }}>
      {filteredLanguages.map((item, index) => (
        <Col
          key={item.code + index}
          xs={6}
          className="mt-3"
          name={item.code}
          languageid={item.id}
          onClick={chooseLanguage}
        >
          <Image
            src={item.flag}
            style={{
              width: "64px",
              height: "64px",
              objectFit: "contain",
              borderRadius: "50%",
            }}
            name={item.code}
            languageid={item.id}
            crossOrigin="anonymous"
          />
          <p
            style={{ marginBottom: 0, fontWeight: 600 }}
            className="mt-2"
            name={item.code}
          >
            {item.name}
          </p>
        </Col>
      ))}
    </Row>
  );
}
